@import "tailwind";

//sass-lint:disable no-qualifying-elements
div.boxed-text {
  background-color: #ebeff3;
  display: inline-block;
  font-family: "Larsseit", Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  padding: 8px 10px 6px 10px;
  text-transform: uppercase;
}

span.pink-text {
  @apply text-pink
  font-dea-regular;
}

span.green-text {
  @apply text-green
  font-medium
  font-dea-regular;
}

span.pink-text--big {
  @apply text-pink
  text-45
  font-bold
  mt-2
  block
  font-dea-regular;
}

span.highlighted-text {
  background-color: #005896;
  color: white;
  font-family: "Larsseit", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.3;
  padding: 2px 1px;
}

div.custom-icon {
  align-items: center;
  display: flex;

  svg {
    width: 40px;
  }

  p {
    margin: 12px 0 0 8px;
  }
}
