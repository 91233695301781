@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-dea-regular;
  }
}

.deaflix {
  color: currentColor;
}
