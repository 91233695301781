@import "tailwind";

//sass-lint:disable no-qualifying-elements
div.boxed-text {
  @apply bg-lightest-gray
  inline-block
  font-dea-regular
  text-12
  tracking-[1px]
  mb-2
  pt-2
  px-2.5
  pb-1.5
  uppercase;
}

span.pink-text {
  @apply text-pink
  font-dea-regular
  tracking-wide;
}

span.green-text {
  @apply text-green
  font-medium
  font-dea-regular;
}

span.pink-text--big {
  @apply text-pink
  text-45
  font-bold
  mt-2
  block
  font-dea-regular;
}

span.highlighted-text {
  @apply bg-highlight-blue
  text-white
  font-dea-regular
  text-18
  leading-[1.3]
  py-0.5
  px-px;
}

div.custom-icon {
  @apply flex
  items-center;

  svg {
    @apply w-10;
  }

  p {
    @apply mt-3
    mr-0
    mb-0
    ml-2;
  }
}
